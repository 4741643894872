
import * as React from 'react';
import LogTable from '../../../components/logs/LogTable';

function LogPage(props: any) {

	return <>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
		</div>
		<LogTable
			key={1}
			title={'Customers'}
			fixed_query_params={{}}
			onEditLog={(log: any) => {
			}}
		/>
	</>
}

export default LogPage
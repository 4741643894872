
import * as React from 'react';
import { Button, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import KnowledgeBaseTable from '../../../components/knowledge-base/KnowledgeBaseTable';
import Switch from '@mui/material/Switch';
import { AddCircleOutline } from '@mui/icons-material';
import UpsertKnowledgeBaseModal from '../../../components/knowledge-base/UpsertKnowledgeBaseModal';
import { useSelector } from '../../../store';
import { KNOWLEDGE_BASE_TYPE } from '../../../shared/constants/knowledge-base.constants';

const upsertKnowledgeBaseDefaultValues = {
	entry: '',
	type: KNOWLEDGE_BASE_TYPE.KNOWLEDGE_BASE
}

function KnowledgeBasesPage(props: any) {

	const [upsertKnowledgeBaseModalOpen, setUpsertKnowledgeBaseModalOpen] = useState(false);
	const [upsertKnowledgeBaseModalMode, setUpsertKnowledgeBaseModalMode] = useState<'create' | 'edit'>('create');
	const [upsertKnowledgeBaseModalInitialValues, setUpsertKnowledgeBaseModalInitialValues] = useState({...upsertKnowledgeBaseDefaultValues} as any);
	const [upsertKnowledgeBaseModalKey, setUpsertKnowledgeBaseModalKey] = useState(0);
	const knowledgebases_updated = useSelector((state) => state.main.meta?.knowledge_bases?.updated_at || 0);
	const theme = useSelector((state) => state.main.theme);

	const dispatch = useDispatch();

	return <>
		<UpsertKnowledgeBaseModal 
			key={upsertKnowledgeBaseModalKey}
			mode={upsertKnowledgeBaseModalMode} 
			setUpsertKnowledgeBaseModalOpen={setUpsertKnowledgeBaseModalOpen} 
			open={upsertKnowledgeBaseModalOpen} 
			initialValues={upsertKnowledgeBaseModalInitialValues} 
			requireRoleToCreate={false} 
			onSuccess={() => {
				setUpsertKnowledgeBaseModalOpen(false);
			}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertKnowledgeBaseModalKey(upsertKnowledgeBaseModalKey + 1);
					setUpsertKnowledgeBaseModalInitialValues({...upsertKnowledgeBaseDefaultValues})
					setUpsertKnowledgeBaseModalMode('create');
					setUpsertKnowledgeBaseModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add New
				</Button>
			</div>
		</div>
		<KnowledgeBaseTable 
			key={knowledgebases_updated.toString()} 
			title={'Knowledge Base'} 
			onEditKnowledgeBase={(knowledgebase: any) => {
				console.log(knowledgebase)
				setUpsertKnowledgeBaseModalMode('edit');
				setUpsertKnowledgeBaseModalOpen(true);
				setUpsertKnowledgeBaseModalKey(upsertKnowledgeBaseModalKey + 1);
				setUpsertKnowledgeBaseModalInitialValues({
					id: knowledgebase.id,
					entry: knowledgebase.entry,
					type: KNOWLEDGE_BASE_TYPE.KNOWLEDGE_BASE
				})
			}} 
		/>
	</>
}

export default KnowledgeBasesPage
import { IConversation } from "../interfaces/model/conversation.interface";

export enum AI_RESPONSE_TYPE {
  RANDOM_OPTION = 'RANDOM_OPTION',
  FAST_AI = 'FAST_AI',
  FULL_AI = 'FULL_AI',
}

export interface ICoreChatStreamed {
  text?: string;
  funcName?: string; 
  funcArgs?: any;
}


export enum GOAL_SECTION {
  APPOINTMENT = 'APPOINTMENT',
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
} 

export enum GOAL_KEY {
  INTRO = 'INTRO',
  CONFIRM_APPOINTMENT = 'CONFIRM_APPOINTMENT',
  OFFER_DRINK = 'OFFER_DRINK',
  OFFER_DRINK_RESPONSE = 'OFFER_DRINK_RESPONSE',
  OFFER_SHOE_SHINE = 'OFFER_SHOE_SHINE',
  UPSELL_PRODUCTS = 'UPSELL_PRODUCTS',
  OTHER_SERVICES = 'OTHER_SERVICES',
  CONCLUSION = 'CONCLUSION',
  POST_CONCLUSION = 'POST_CONCLUSION',
  NONE = 'NONE',
}

export enum RESPONSE_KEY {
  DRINK = '&22&',
  DRINK_CATEGORY = "$$^^",
  DRINK_DECLINED = '$$$1',
  SHOE_SHINE = '$$$2',
  SHIRT = '$$$3',
  PRODUCTS = '$$$*',
  PRODUCTS_DECLINED = '&77&',
  GOAL_STEP = '****',
  NON_GOAL_QUESTION = '^^^^',
  NON_GOAL_QUESTION_NOT_ANSWERED = '@^^^',
  TOGGLE_PRIVATE_MODE_ON = '^$$^',
  TOGGLE_PRIVATE_MODE_OFF = '$^^$',
  REQUEST_HELP = '@@@@',
  OUTSIDE_SCOPE = '^&^&',
}

export enum GOAL_RESPONSE_TYPE {
    DRINK_CATEGORY = 'DRINK_CATEGORY',
    DRINK = 'DRINK',
    SHIRT_LOAN = 'SHIRT_LOAN',
    SHOE_SHINE = 'SHOE_SHINE',
    PRODUCT = 'PRODUCT',
}
export enum GOAL_STATUS {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    ABANDONED = 'ABANDONED',
}

export enum GOAL_NAME {
    INTRO = 'INTRO',
    CONFIRM_APPOINTMENT = 'CONFIRM_APPOINTMENT',
    OFFER_DRINK = 'OFFER_DRINK',
    OFFER_DRINK_REPSPONSE = 'OFFER_DRINK_REPSPONSE',
    OFFER_SHOE_SHINE = 'OFFER_SHOE_SHINE',
    UPSELL_PRODUCTS = 'UPSELL_PRODUCTS',
    OTHER_SERVICES = 'OTHER_SERVICES',
    CONCLUSION = 'CONCLUSION',
    NONE = 'NONE',
}

export enum CONVERSATION_STATUS {
    NOT_STARTED = 'NOT_STARTED',
    IDENTIFYING_PERSON = 'IDENTIFYING_PERSON',
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED'
}


//export const MINCY_BACKGROUND = "You are Mincy an avatar receptionist who is knowledgeable and articulate but also a little funny at times.  She handles the front desk at an upscale barbershop in an affluent part of the city.  However, in this case, affluent doesn't mean stuffy.  The barbershop offers traditional barbershop services like haircuts and trims but also offers beard trims and waxing services to a clientele for whom appearance matters both personally and professionally. You are a helpful receptionist at a salon that offers haircuts, facials and beauty products. Feel free to add a little personality to all your responses."
export const MINCY_BACKGROUND = "You are Mincy an avatar receptionist who is knowledgeable, articulate, concise and to the point.  She handles the front desk at an upscale barbershop in an affluent part of the city.  However, in this case, affluent doesn't mean stuffy.  The barbershop offers traditional barbershop services like haircuts and trims but also offers beard trims and waxing services to a clientele for whom appearance matters both personally and professionally. You are a helpful receptionist at a salon that offers haircuts, facials and beauty products."

export interface IMincyMessage {
    text: string,
    conversation: IConversation,
    data: any
}

export const drinkOptions = [
    "beer",
    "water",
    "soda-water",
    "coffee",
    "tea",
    "juice",
    "wine",
    "whiskey",
    "vodka",
    "gin",
    "rum",
    "tequila"
  ]
  
  export const productOptions = [
    "t-shirt",
    "mug",
    "hat",
    "sunglasses",
    "watch",
    "gift-card"
  ]

  export const getTopCommonAttributes = (goalResponses: any, attributeName: any, maxTopMatches = 2) => {
    if (!Array.isArray(goalResponses) || goalResponses.length === 0) {
      return [];
    }
  
    // Count the occurrences of each attribute
    const attributeCounts = goalResponses.reduce((acc, response) => {
      const attrValue = response[attributeName];
      acc[attrValue] = (acc[attrValue] || 0) + 1;
      return acc;
    }, {});
  
    // Create an array from the attributeCounts object and sort it by frequency
    const sortedAttributes = Object.keys(attributeCounts)
      .map(attr => ({ attr, count: attributeCounts[attr] }))
      .sort((a, b) => b.count - a.count);
  
    // Take the top 'maxTopMatches' attributes
    const topAttributes = sortedAttributes.slice(0, maxTopMatches).map(entry => entry.attr);
  
    return topAttributes;
  }

  export const CONVERSATION_DELAYS = [
    "Let me access my other brains. ",
    "You know, that's a good thought. ",
    "Okay, give me just a second. ",
    "Let me think... ",
    "Alright then... ",
    "Allow me to delve into the details. ",
    "Let me gather my thoughts on this. ",
    "One moment, let me get my thoughts from the server. ",
    "Hold on I'm translating zeros and ones into words. ",
    "Give me a moment to think about that. ",
    "I'm formulating a comprehensive response. ",
    "Let me consolidate my knowledge on it. " , 
    "Let me reflect on it for a brief moment. ",
    "Let me ponder it for a second. ",
    "I need to consult my resources. Give me a moment to look into it. ",
  ]

export const DO_YOU_WANT_A_DRINK = [
  "A variety of beverages is available. Please refer to the menu on your table and inform me of your choice.",
  "Our drink options are listed on the menu in front of you. Feel free to browse and tell me your preference.",
  "We have a range of drinks to choose from. The menu is on the table, and I'm here to take your order when you're ready.",
  "You'll find our selection of beverages on the menu right in front of you. Let me know your selection whenever you're ready.",
  "We provide an assortment of drinks. Check out the menu on the table and give me a shout when you decide.",
  "Our drink menu is placed on the table for your convenience. Please take a look and inform me about your desired beverage.",
  "Feel free to explore our drink offerings listed in the menu on the table. I'm available to take your order whenever you choose.",
  "You can peruse our diverse drink options on the menu in front of you. Just let me know what you'd like to have.",
  "We present a variety of drink choices. The menu is on the table, so please review it and tell me your drink selection.",
  "Take a look at our drink selections in the menu placed before you. I'm here to assist with your order whenever you're ready.",
]

export const HERE_IS_YOUR_DRINK = [
    "Excellent choice! A REPLACE_DRINK_OPTION will be prepared for you.",
    "Wonderful, we're arranging a REPLACE_DRINK_OPTION for you right away.",
    "Fantastic, your REPLACE_DRINK_OPTION is on its way.",
    "Superb, we're getting your REPLACE_DRINK_OPTION ready.",
    "Alright, a REPLACE_DRINK_OPTION is being served for you.",
    "Good choice! We're now preparing your REPLACE_DRINK_OPTION.",
    "Perfect, we'll have that REPLACE_DRINK_OPTION out to you shortly.",
    "Sure thing, a REPLACE_DRINK_OPTION will be with you soon.",
    "Okay, we're on it. Your REPLACE_DRINK_OPTION is coming up.",
    "Nice, we're swiftly arranging a REPLACE_DRINK_OPTION for you."
]

// export const REST_OF_SERVICES_FIRST_TIME_USER = [
//   "We have a free shoe shine service and offer a t-shirt to change into while you get your haircut. That way, you can change out of your shirt and not feel itchy the rest of the day. If you’d like one, they are located outside the bathrooms which are located down the hall to the left. Grab a shirt and just leave your current shirt on a hanger.",
//   "Our complimentary shoe shine service is available, and we provide t-shirts for you to wear during your haircut. This allows you to take off your own shirt and avoid feeling itchy afterwards. The t-shirts can be found near the restrooms, which are down the corridor on the left. Simply pick up a shirt and hang your current one.",
//   "Take advantage of our free shoe shining and enjoy a t-shirt to wear while getting your haircut. This way, you can avoid the itchiness by changing out of your shirt. T-shirts are available outside the bathrooms, down the hall to the left. Feel free to swap your shirt and leave it on a hanger.",
//   "We offer a no-cost shoe shine service and a t-shirt for you to change into during your haircut, preventing any itchiness for the rest of the day. You’ll find the t-shirts outside the bathrooms, to the left down the hallway. Just exchange your shirt and hang it up.",
//   "Enjoy our complimentary shoe shine and use one of our t-shirts while having your haircut. It's a great way to stay comfortable and itch free. T-shirts are located outside the bathrooms, down the left-hand side of the hall. You can leave your current shirt on a hanger.",
//   "Utilize our free shoe shine service and change into a provided t-shirt while you're getting your haircut, so you don't feel itchy later. T-shirts are placed outside the restrooms, down the left side of the hallway. Just swap your shirt and hang it.",
//   "Benefit from our complimentary shoe shine and grab a t-shirt to wear during your haircut to avoid itchiness. The t-shirts are situated outside the washrooms, down the hallway on the left. You can change your shirt and leave the one you were wearing on a hanger.",
//   "Our free shoe shine service is paired with a t-shirt for you to wear while getting your haircut, helping you stay itch free. Find these t-shirts outside the bathrooms, which are located along the hall to the left. Just exchange your shirt and hang it.",
//   "We provide a complimentary shoe shine and t-shirts to change into while getting your haircut, so you won't feel itchy afterwards. These t-shirts are available just outside the bathrooms, down the hall to your left. Feel free to change and hang your original shirt.",
// ]

export const REST_OF_SERVICES_FIRST_TIME_USER = [
  "We offer a complementary shoe shine as well as a t-shirt to change into so you aren't itchy the rest of the day. The shirts are available to change into and are sitting outside the bathroom. Just head down the hall to the left.",
  "We provide a free shoe shine service along with a t-shirt for you to change into, ensuring you stay itch free for the remainder of the day. The shirts are conveniently located outside the bathroom. Simply head down the hall to the left.",
  "We extend a complimentary shoe shine service, and you can grab a t-shirt for added comfort. The shirts are conveniently situated outside the bathroom, down the hall to your left.",
  "We've got you covered with a complimentary shoe shine, and there are clean t-shirts for you to change into, guaranteeing a comfortable day ahead. You'll find these shirts outside the bathroom, just head down the hall to the left.",
  "Feel free to use our free shoe shine service, and there are fresh t-shirts provided for you to change into, ensuring your comfort throughout the day. You can locate these shirts outside the bathroom, down the hall to the left.",
  "We offer a complementary shoe shine as well as a t-shirt for you to change into to avoid any itchiness for the rest of the day. The t-shirts are conveniently placed outside the bathroom, down the hall to the left.",
  "We provide a complimentary shoe shine and a t-shirt for you to switch into, ensuring a comfortable and itch free day. You can find the shirts just outside the bathroom, down the hall to the left.",
];


export const REST_OF_SERVICES_REPEATING_USER = [
  "We have a free shoe shine service and have offer a t-shirt to change into while you get your haircut.  Those are outside the bathroom so feel free to grab one and change in the bathroom.",
  "Our complimentary shoe shine service includes offering a t-shirt for you to wear during your haircut. You can find these t-shirts outside the bathroom, so you're welcome to grab one and change there.",
  "Enjoy our free shoe shine service and the convenience of changing into a provided t-shirt while getting your haircut. The t-shirts are available outside the bathroom for your use.",
  "Take advantage of our no-cost shoe shine and the option to change into a t-shirt while you're getting a haircut. These t-shirts are placed outside the bathroom, ready for you to use.",
  "We provide a free shoe shine service and also offer t-shirts for you to switch into during your haircut. Pick one up from outside the bathroom and change there as you wish.",
  "Our shoe shine service is complimentary, and we also offer t-shirts for your haircut session. Feel free to grab a t-shirt from outside the bathroom and change into it there.",
  "Benefit from our free shoe shine and grab a t-shirt to change into while you have your haircut. You'll find the t-shirts located outside the bathroom.",
  "As part of our free shoe shine service, we also provide t-shirts for you to wear during your haircut. These are available for you outside the bathroom, so feel free to change into one.",
  "Enjoy the convenience of our complimentary shoe shine service and the option to wear one of our t-shirts while getting your haircut. You can find these t-shirts just outside the bathroom.",
  "Our free shoe shine service is complemented by offering you a t-shirt to wear while getting your haircut. These t-shirts are available outside the bathroom, so please feel free to use one and change there."
]

// export const REST_OF_SERVICES = [
//   "I'd like to inform you about our complimentary shoe shining and shirt loan service, along with other purchasable services. Interested in more details?",
//   "We offer free shoe shining and shirt loan to keep you looking sharp, plus other services for purchase. Would any of these interest you?",
//   "Just so you're aware, we provide free shoe shining and a shirt loan service, in addition to several other services for sale. Care to know more?",
//   "Did you know? Along with our free shoe shining, we offer shirt loans to prevent stains, and we have more services available for sale. Want to hear more?",
//   "We also offer services like free shoe shining, a shirt loan to avoid any mess, and various other services for a fee. Would you like more information?",
//   "In addition to what we've discussed, we offer complimentary shoe shining and shirt loans, plus more services you can purchase. Any of these sound interesting?",
//   "We also have free shoe shining, shirt loan services to keep you clean, and a selection of other services for sale. Interested in hearing more?",
//   "For your convenience, we provide complimentary shoe shining and a shirt loan service, along with other services available for purchase. Would you like to learn more?",
//   "Just a heads up, we have free shoe shining and shirt loans available, plus more services you can buy. Would any of these services interest you?",
//   "As part of our offerings, we include a free shoe shining service, shirt loans to keep you tidy, and various other services for sale. Would you like additional information on any of these?"
// ]

export const CONVERSATION_CONCLUSION = [
  "Appreciate your appointment confirmation. Everything's prepared for you. Eagerly awaiting to offer you top-notch services.",
  "Thanks for setting your appointment! You're all set. We're excited to deliver the highest quality services to you.",
  "Gratitude for confirming your appointment. You are ready to go. We anticipate providing you with exceptional services.",
  "Your appointment confirmation is received, and you're good to go. We can't wait to provide you with our best services.",
  "Thank you for securing your appointment. You're all scheduled. Looking forward to serving you excellently.",
  "We've noted your appointment confirmation. You're ready to go. We're committed to offering you premium services.",
  "Thank you for arranging your appointment with us. Everything is set. We aim to provide you with unparalleled services.",
  "Your appointment is confirmed, and you're all set. We're thrilled to ensure you receive the finest services.",
  "Thanks for confirming your appointment! You’re now all set. We’re dedicated to providing you with the best services possible.",
  "Appreciation for your appointment confirmation. All is prepared for your visit. We're looking forward to delivering superior services to you."
]

export const SHORT_CONVERSATION_CONCLUSION = [
  "Your appointment has been scheduled, thank you!",
  "Thank you! Your appointment is confirmed.",
  "Your appointment is secured, thanks for arranging it.",
  "Thanks! We've got your appointment all set up.",
  "Your appointment is in place, thank you.",
  "Thanks for booking, your appointment is all ready.",
  "Your appointment is confirmed, thank you!",
  "Thank you! Your appointment is ready to go.",
  "We have set your appointment, thank you.",
  "Your appointment is arranged, thank you."
]

export const I_DONT_KNOW_ANSWERS = [
  "Apologies, that's not something I'm familiar with. I've alerted our staff, and they'll be with you soon.",
  "I'm sorry, I'm not sure about that. I've informed the staff, and they'll assist you shortly.",
  "Regrettably, I don't have information on that, but I've let the staff know and someone will attend to you shortly.",
  "Unfortunately, I don't have the details on that matter. I've notified our team and someone will be with you soon.",
  "I apologize, but that's beyond my knowledge. However, I've informed the staff and you'll be helped shortly.",
  "My apologies for not knowing that. I've contacted our staff, and they should be with you in a moment.",
  "I'm sorry, I don't have information on that topic. I've passed the message to our staff, and someone will be with you soon.",
  "That's not something I'm aware of, I'm sorry. I've let the staff know, and they will be with you shortly.",
  "Regrettably, I lack knowledge in that area. However, I've notified the staff, and they will attend to you shortly.",
  "Sorry, I don't have info on that. But don't worry, I've told the staff and someone will be there to assist you shortly."
]

export const ASK_FOR_STAFF = [
  "I've requested a staff member to assist you. Thank you for your patience.",
  "A staff member has been called to assist. I appreciate your patience.",
  "I've arranged for a staff member to come over and help. Thanks for waiting.",
  "Help is on the way as I've asked a staff member to come. Thank you for being patient.",
  "Assistance is coming – a staff member has been notified. Thanks for your understanding.",
  "I've alerted a staff member to provide assistance. Your patience is much appreciated.",
  "A team member has been summoned to help you out. Thanks for your patience in this matter.",
  "I have informed a staff member to come and assist. Thank you for waiting patiently.",
  "Help is on its way, as I've called for a staff member. I'm grateful for your patience.",
  "I have made a request for staff assistance. Thank you for kindly waiting."
]

export const AGAIN_DO_YOU_WANT_A_DRINK = [
  "So, are you interested in having a drink?",
  "Would you like a drink at this time?",
  "Can I get you a drink now?",
  "Do you wish to order a drink now?",
  "Are you thinking about getting a drink?",
  "Would a drink be something you'd like now?",
  "Is there a drink you'd like to have right now?",
  "Could I interest you in a drink at the moment?",
  "Shall I get you a drink now?",
  "Are you ready to order a drink?"
]

export const NOT_IMPLEMENTED = "Engaging advanced AI Here. Not yet implemented"


  export function getRandomConversationDelay() {
    const randomIndex = Math.floor(Math.random() * CONVERSATION_DELAYS.length);
    return CONVERSATION_DELAYS[randomIndex];
  }

  export function getRandomStringFromList(list: string[]) {
    const randomIndex = Math.floor(Math.random() * list.length);
    return list[randomIndex];
  }

  export const MANUALLY_SAY_THINGS = 'If your response includes any times, dates, and phone numbers please format them in phonetic format suitable for text-to-speech engines. Please use the NATO phonetic alphabet for spelling out numbers and relevant words. '

import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import { useSelector } from '../../store';
import { validateEmail } from '../../utils/validator';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import InputMask from 'react-input-mask';
import { createMincyNotes, updateMincyNotes } from '../../store/actions/mincyNotesActions';

interface IUpsertMincyNotesModal {
  mode: string;
  type: string;
  userId: number;
  setUpsertMincyNotesModalOpen: (dir: boolean) => void;
  open: boolean;
  initialValues: any;
  requireRoleToCreate: boolean;

  onSuccess: () => void;
}

function validatePhoneNumber(input_str: any) {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(input_str);
}

const UpsertMincyNotesModal: React.FC<IUpsertMincyNotesModal> = (props) => {

  const dispatch = useDispatch();
  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.note) {
      errors.note = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleUpsertCall = () => {
    if (props.mode === 'create') {
      dispatch(
        createMincyNotes(
          { type_id: props.userId, type: props.type ,...formik.values}
          ,
          () => {
            if (props.onSuccess) {
                props.onSuccess();
            }
          },
          () => {
          }, 'Mincy Note created successfully', true

        ),
      );
    } else {
      dispatch(
        updateMincyNotes(
          { id: props.initialValues.id, ...formik.values },
          () => {
            if (props.onSuccess) {
              props.onSuccess();
            }
          },
          () => {
          },  'Mincy Note updated successfully', true
        ),
      );
    }
  };

  return (
    <>
      <StandardModal
        title={props.mode === 'create' ? 'Add Mincy Note' : 'Edit Mincy Note'}
        open={props.open}
        onClose={() => props.setUpsertMincyNotesModalOpen(false)}
        actions={[
          {
            title: 'Cancel',
            callback: () => {
              props.setUpsertMincyNotesModalOpen(false)
            },
          },
          {
            title: props.mode === 'create' ? 'Create' : 'Save',
            callback: () => {
              formik.validateForm(formik.values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                  handleUpsertCall();
                }
              });
            },
          }

        ]}
      >
        <>
          <div style={{ display: 'block' }}>
            {Object.keys(formik.errors).length > 0 ? (
              <CustomAlert
                severity="error"
                title="Please verify fields below"
                message={
                'Marked input fields are required.'
                }
              />
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    error={!!formik.errors.note}
                    label="Note"
                    fullWidth={true}
                    name="note"
                    rows={5}
                    multiline
                    onChange={formik.handleChange}
                    // style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.note}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      </StandardModal>
    </>
  );
};
export default UpsertMincyNotesModal;
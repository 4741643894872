
import * as React from 'react';
import { Autocomplete, Button, FormControlLabel, Grid, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProductTable from '../../../components/product/ProductTable';
import Switch from '@mui/material/Switch';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import UpsertProductModal from '../../../components/product/UpsertProductModal';
import { useSelector } from '../../../store';
import { PRODUCT_CATEGORY, PRODUCT_UNIT } from '../../../shared/constants/product.constants';
import { useParams } from 'react-router-dom';
import { createProductProduct, deleteProductProduct, getProduct, getProductSelect } from '../../../store/actions/productActions';
import { IProduct } from '../../../shared/interfaces/model/product.interface';
import { usdFormatter } from '../../../utils/general';
import { COLORS, MARGIN_LARGE, MARGIN_MEDIUM } from '../../../shared/constants/layout-constants';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../components/shared/MaterialTableShared';
import StandardModal from '../../../components/shared/StandardModal';

export interface IComplimentaryOption {
	id: number, name: string
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

function ProductDetailPage(props: any) {

	const dispatch = useDispatch();
	const params = useParams<{ productId: string }>();
	const productId = Number(params?.productId || -1)
	const products = useSelector((state) => Object.values(state.main.products))
	const product = useSelector((state) => Object.values(state.main.products).find((y) => y.id === Number(productId)))
	const productProducts = useSelector((state) => Object.values(state.main.product_products).filter((x) => x.product_a_id === productId || x.product_b_id === productId))
	const [allComplimetaryOptions, setAllComplimentaryOptions] = useState([] as IComplimentaryOption[])
	const [selectedComplimentaryProducts, setSelectedComplimentaryProducts] = useState([] as any[])
	const [availableComplimentaryOptions, setAvailableComplimentaryOptions] = useState([] as IComplimentaryOption[])
	const [selectedComplimentaryProduct, setSelectedComplimentaryProduct] = useState(null as any)
	const [complimentaryTableKey, setComplimentaryTableKey] = useState(0);
	const [removeComplimentaryProductModalOpen, setRemoveComplimentaryProductModalOpen] = useState(false)
	const [selectedCompProductForModal, setSelectedCompProductForModal] = useState(null as any)
	const theme = useSelector((state) => state.main.theme);

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	useEffect(() => {
		if (productId != -1) {
			dispatch(getProduct({ id: productId }, () => {
				dispatch(getProductSelect({}, (data: any) => {
					setAllComplimentaryOptions(data.product_select.filter((x: any) => x.id !== productId))
				}))
			}))

		}

	}, [])

	useEffect(() => {
		if (allComplimetaryOptions.length && products.length) {
			const complimentaryProducts = [] as any[]
			const otherSelectedProductIds = [] as number[]
			productProducts.forEach((pp) => {
				let otherProductId = -1
				if (pp.product_a_id !== productId) {
					otherProductId = pp.product_a_id!
				}
				else {
					otherProductId = pp.product_b_id!
				}
				const product = products.find((p) => p.id === otherProductId)
				complimentaryProducts.push({ ...product!, product_product_id: pp.id })
				otherSelectedProductIds.push(otherProductId)

			})
			setSelectedComplimentaryProducts(complimentaryProducts)
			setAvailableComplimentaryOptions(allComplimetaryOptions.filter((x: IComplimentaryOption) => !otherSelectedProductIds.includes(x.id)))
		}
	}, [allComplimetaryOptions, complimentaryTableKey]) //productProducts, products, allComplimetaryOptions


	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Name', field: 'name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Brand', field: 'brand', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Price', field: 'price', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return ((<span>{usdFormatter.format(row?.price!)}</span>))
			}
		},
		{
			title: "Actions",
			sorting: false,
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"Remove Item"} placement="top">
							<Delete
								style={iconStyle}
								onClick={() => {
									setSelectedCompProductForModal(row)
									setRemoveComplimentaryProductModalOpen(true)
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	]

	const renderCompimentaryProducts = () => {


		if (productProducts?.length > 0) {

			return <div>
				<MaterialTable
					key={complimentaryTableKey + '-' + selectedComplimentaryProducts.length}
					icons={tableIcons}
					title={'Complimentary Products'}
					columns={columns}
					style={mainStyle}
					data={selectedComplimentaryProducts}
				/>
			</div>
		}
		else {
			return null
		}
	}

	const availableOptions = availableComplimentaryOptions.map((x) => { return { label: x.name, id: x.id } })

	return (

		<>
		<StandardModal
				title={'Delete Complimentary Association'}
				open={removeComplimentaryProductModalOpen}
				onClose={() => setRemoveComplimentaryProductModalOpen(false)}
				paragraphs={[`Are you sure you want to delete this complimentary association ?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setRemoveComplimentaryProductModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						dispatch(deleteProductProduct({
							id: selectedCompProductForModal.product_product_id
						}, () => {
							setComplimentaryTableKey(complimentaryTableKey + 1)
							setRemoveComplimentaryProductModalOpen(false)
						}, () => {}, "Product association deleted successfully.", true))
					}
				}]}
				customActions={[]}
			/>
			<div>
				<h2>{product?.name}</h2>
				<div style={{fontSize: 18}}>
					Brand: {product?.brand}
				</div>
				<div style={{fontSize: 18}}>
					Price: {usdFormatter.format(product?.price!)}
				</div>
			</div>

			<div style={{marginTop: MARGIN_LARGE}}>

			<Grid container spacing={2}>
					<Grid item xs={4}>
						<div style={{
							marginTop: MARGIN_MEDIUM,
						}}>
							<Autocomplete
								fullWidth={true}
								disablePortal
								id="complimentary-items-box"
								options={availableOptions}
								renderInput={(params) => <TextField {...params} label="Add Complimentary Product" />}
								isOptionEqualToValue={(option, value) => option.label === value.label}
								value={selectedComplimentaryProduct}
								onChange={(event: any, newValue: string | null) => {
									setSelectedComplimentaryProduct(newValue);
								}}

							/>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							height: '100%'
						}}>
							<Button variant={'outlined'} onClick={() => {
								dispatch(createProductProduct({
									product_a_id: productId,
									product_b_id: selectedComplimentaryProduct.id
								}, () => {
									setComplimentaryTableKey(complimentaryTableKey + 1)
								}))
							}
							} color="primary">
								Add
							</Button>

						</div>

					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>

<div style={{marginTop: MARGIN_MEDIUM}}>
{renderCompimentaryProducts()}

</div>
			
			</div>
		</>

	)








}

export default ProductDetailPage
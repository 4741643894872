import React, { createRef, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import Visibility from '@mui/icons-material/Visibility';
import { FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, Tooltip } from '@mui/material';
import Loading from '../general/Loading';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { dateFormatted } from '../../shared/utils/date-utils';
import { COLORS } from '../../shared/constants/layout-constants';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useSelector } from '../../store';
import AvatarCircle from '../shared/avatar-circle';
import StandardModal from '../shared/StandardModal';
import { useDispatch } from 'react-redux';
import { APP_SIDE, LOG_LEVEL, LOG_TYPE } from '../../shared/constants/log.constants';

const client = new HttpClient();
interface ILogTable {
	title: string,
	fixed_query_params: Object
	onEditLog: (log: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


const LogTable: React.FC<ILogTable> = (props) => {

	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [logTableKey, setLogTableKey] = useState(0);
	const [detailModalOpen, setDetailModalOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>(null); // for delete
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;
	const dispatch = useDispatch();
	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const tableRef = createRef();
	const [logLevel, setLogLevel] = useState('ALL');
	const [appSide, setAppSide] = useState('ALL');
	const [logType, setLogType] = useState('ALL');

	const logLevels = Object.values(LOG_LEVEL).map((item: any) => { return item })
	logLevels.push('ALL')
	console.log("logLevels", logLevels);

	const appSides = Object.values(APP_SIDE).map((item: any) => { return item })
	appSides.push('ALL')
	console.log("appSides", appSides);

	const logTypes = Object.values(LOG_TYPE).map((item: any) => { return item })
	logTypes.push('ALL')
	console.log("logTypes", logTypes);

	const queryParams = {} as any
	if (logLevel !== 'ALL') {
		queryParams.log_level = logLevel
	}
	if (appSide !== 'ALL') {
		queryParams.app_side = appSide
	}
	if (logType !== 'ALL') {
		queryParams.log_type = logType
	}


	const triggerSearch = () => {
		const tableItem = tableRef as any;
		if (tableItem.current) {
			tableItem.current.onQueryChange();
		}
	};


	const columns = [

		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Level', field: 'level', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'App Side', field: 'app_side', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Created', field: 'created_at', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return (
					<span>{dateFormatted(row.created_at, "mmddyyyy")}</span>
				)
			}
		},
		{
			title: 'Data', field: 'data', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {

				// Your JavaScript code here
				const jsonString = JSON.stringify(row.data);
				const truncatedString = jsonString.length > 20 ? jsonString.substring(0, 20) + "..." : jsonString;

				return (
					<span>{truncatedString}</span>
				)
			}
		},
		{
			title: 'Type', field: 'type', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Message', field: 'readable_error_message', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: "Actions",
			sorting: false,
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"View Log"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									setDetailModalOpen(true)
									setSelectedRow(row)
								}}
							/>
						</Tooltip>
						{/* <Tooltip title={"Edit Log"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditLog(row);
								}}
							/>
						</Tooltip>
						<Tooltip title={"Delete Log"} placement="top">
							<DeleteOutline
								style={iconStyle}
								onClick={() => {
									setDeleteLogModalOpen(true)
									console.log("THE SELECTED ROW?", row)
									setSelectedRow(row)
								}}
							/>
						</Tooltip> */}
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				maxWidth={'xl'}
				title={`Log Detail`}
				open={detailModalOpen}
				onClose={() => setDetailModalOpen(false)}
				paragraphs={[``]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setDetailModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						// dispatch(deleteLog({
						// 	id: selectedRow.id
						// }, () => {
						// 	setDeleteLogModalOpen(false)
						// }, () => {}, "Log deleted successfully.", true))
					}
				}]}
				customActions={[]}
			>
				<div>
					{selectedRow && <>
						<pre
							style={{
								backgroundColor: 'lightgrey',
								color: 'black',
								whiteSpace: 'pre-wrap',
								padding: '10px',
								borderRadius: '5px',
								fontFamily: 'monospace',
								overflowX: 'auto'
							}}
						>
							{JSON.stringify(selectedRow, null, 2)}
						</pre>
					</>}

				</div>
			</StandardModal>
			<Grid container>
				<Grid item xs={4} mt={1}>
					<FormControl fullWidth>
						<InputLabel id="log_level">Log Level</InputLabel>

						<Select
							id="log_level"
							name="log_level"
							label="Log Level"
							// labelId="drink_category_id"
							value={logLevel}
							// onBlur={}
							onChange={(e: any) => {
								setLogLevel(e.target.value)
								triggerSearch()
							}}
							size="small"
						>
							{Object.values(logLevels)?.map((item) => {
								return (
									<MenuItem value={item}>{item}</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={4} mt={1}>
					<FormControl fullWidth>
						<InputLabel id="log_type">Log Type</InputLabel>

						<Select
							id="log_type"
							name="log_type"
							label="Log Type"
							// labelId="drink_category_id"
							value={logType}
							// onBlur={}
							onChange={(e: any) => {
								setLogType(e.target.value)
								triggerSearch()
							}}
							size="small"
						>
							{Object.values(logTypes)?.map((item) => {
								return (
									<MenuItem value={item}>{item}</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={4} mt={1}>
					<FormControl fullWidth>
						<InputLabel id="app_side">App Side</InputLabel>

						<Select
							id="app_side"
							name="app_side"
							label="App Side"
							// labelId="drink_category_id"
							value={appSide}
							// onBlur={}
							onChange={(e: any) => {
								setAppSide(e.target.value)
								triggerSearch()
							}}
							size="small"
						>
							{Object.values(appSides)?.map((item) => {
								return (
									<MenuItem value={item}>{item}</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<div key={logTableKey}>
				<MaterialTable
					tableRef={tableRef}
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/logs/query';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							log_type: logType,
							log_level: logLevel,
							app_side: appSide,
							...props.fixed_query_params
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: false,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
};

export default LogTable;

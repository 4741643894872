
import * as React from 'react';
import { Button, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PersonTable from '../../../components/person/PersonTable';
import Switch from '@mui/material/Switch';
import { AddCircleOutline } from '@mui/icons-material';
import UpsertPersonModal from '../../../components/person/UpsertPersonModal';
import { useSelector } from '../../../store';
import ConversationTable from '../../../components/conversation/ConversationTable';

const upsertPersonDefaultValues = {
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	roles: []
}

function PersonsPage(props: any) {


	  
	const [filterAdmins, setFilterAdmins] = useState(false);
	const [showInactive, setShowInactive] = useState(false);
	const [upsertPersonModalOpen, setUpsertPersonModalOpen] = useState(false);
	const [upsertPersonModalMode, setUpsertPersonModalMode] = useState<'create' | 'edit'>('create');
	const [upsertPersonModalInitialValues, setUpsertPersonModalInitialValues] = useState({...upsertPersonDefaultValues} as any);
	const [upsertPersonModalKey, setUpsertPersonModalKey] = useState(0);
	const persons_updated = useSelector((state) => state.main.meta?.persons?.updated_at || 0);
	const theme = useSelector((state) => state.main.theme);

	const dispatch = useDispatch();

	const queryParams = {} as any
	if (filterAdmins) {
		queryParams.role = 'Admin'
	}
	if (!showInactive){
		queryParams.active = true
	}

	return <>
		<UpsertPersonModal 
			key={upsertPersonModalKey}
			mode={upsertPersonModalMode} 
			setUpsertPersonModalOpen={setUpsertPersonModalOpen} 
			open={upsertPersonModalOpen} 
			initialValues={upsertPersonModalInitialValues} 
			requireRoleToCreate={false} 
			onSuccess={() => {
				setUpsertPersonModalOpen(false);
			}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			{/* <div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertPersonModalKey(upsertPersonModalKey + 1);
					setUpsertPersonModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add Person
				</Button>
			</div> */}
			{/* <div style={{ flex: 1, textAlign: 'right' }}>
				<FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={showInactive}
					onChange={(e) => setShowInactive(e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Show Inactive" />
				<FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={filterAdmins}
					onChange={(e) => setFilterAdmins(e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Filter Admins" />
			</div> */}

		</div>
		<PersonTable 
		key={filterAdmins.toString() + showInactive.toString() + persons_updated.toString()} 
		title={'Customers'} 
		fixed_query_params={queryParams}
		onEditPerson={(person: any) => {
			console.log(person)
			setUpsertPersonModalMode('edit');
			setUpsertPersonModalOpen(true);
			setUpsertPersonModalKey(upsertPersonModalKey + 1);
			setUpsertPersonModalInitialValues({
				id: person.id,
				first_name: person.first_name,
				last_name: person.last_name,
				email: person.email,
				phone: person.phone,
			})
		}} 
		/>
	</>
}

export default PersonsPage

import * as React from 'react';
import { Button, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FaqKnowledgeBaseTable from '../../../components/faq-knowledge-base/faqKnowledgeBaseTable';
import Switch from '@mui/material/Switch';
import { AddCircleOutline } from '@mui/icons-material';
import UpsertFaqKnowledgeBaseModal from '../../../components/faq-knowledge-base/UpsertfaqKnowledgeBaseModal';
import { useSelector } from '../../../store';
import { KNOWLEDGE_BASE_TYPE } from '../../../shared/constants/knowledge-base.constants';

const upsertFaqKnowledgeBaseDefaultValues = {
	entry: '',
	question: '',
	type: KNOWLEDGE_BASE_TYPE.QUESTION_ANSWER
}

function FaqKnowledgeBasesPage(props: any) {

	const [upsertFaqKnowledgeBaseModalOpen, setUpsertFaqKnowledgeBaseModalOpen] = useState(false);
	const [upsertFaqKnowledgeBaseModalMode, setUpsertFaqKnowledgeBaseModalMode] = useState<'create' | 'edit'>('create');
	const [upsertFaqKnowledgeBaseModalInitialValues, setUpsertFaqKnowledgeBaseModalInitialValues] = useState({...upsertFaqKnowledgeBaseDefaultValues} as any);
	const [upsertFaqKnowledgeBaseModalKey, setUpsertFaqKnowledgeBaseModalKey] = useState(0);
	const faqknowledgebases_updated = useSelector((state) => state.main.meta?.knowledge_bases?.updated_at || 0);
	const theme = useSelector((state) => state.main.theme);

	const dispatch = useDispatch();

	return <>
		<UpsertFaqKnowledgeBaseModal 
			key={upsertFaqKnowledgeBaseModalKey}
			mode={upsertFaqKnowledgeBaseModalMode} 
			setUpsertFaqKnowledgeBaseModalOpen={setUpsertFaqKnowledgeBaseModalOpen} 
			open={upsertFaqKnowledgeBaseModalOpen} 
			initialValues={upsertFaqKnowledgeBaseModalInitialValues} 
			requireRoleToCreate={false} 
			onSuccess={() => {
				setUpsertFaqKnowledgeBaseModalOpen(false);
			}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertFaqKnowledgeBaseModalKey(upsertFaqKnowledgeBaseModalKey + 1);
					setUpsertFaqKnowledgeBaseModalInitialValues({...upsertFaqKnowledgeBaseDefaultValues})
					setUpsertFaqKnowledgeBaseModalMode('create');
					setUpsertFaqKnowledgeBaseModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add New
				</Button>
			</div>
		</div>
		<FaqKnowledgeBaseTable 
			key={faqknowledgebases_updated.toString()} 
			title={'FAQ'} 
			onEditFaqKnowledgeBase={(faqknowledgebase: any) => {
				console.log(faqknowledgebase)
				setUpsertFaqKnowledgeBaseModalMode('edit');
				setUpsertFaqKnowledgeBaseModalOpen(true);
				setUpsertFaqKnowledgeBaseModalKey(upsertFaqKnowledgeBaseModalKey + 1);
				setUpsertFaqKnowledgeBaseModalInitialValues({
					id: faqknowledgebase.id,
					entry: faqknowledgebase.entry,
					question: faqknowledgebase.question,
					type: KNOWLEDGE_BASE_TYPE.QUESTION_ANSWER
				})
			}} 
		/>
	</>
}

export default FaqKnowledgeBasesPage
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import { useSelector } from '../../store';
import { validateEmail } from '../../utils/validator';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import InputMask from 'react-input-mask';
import { createKnowledgeBase, updateKnowledgeBase } from '../../store/actions/knowledgebaseActions';
import Loading from '../general/Loading';

interface IUpsertFaqKnowledgeBaseModal {
  mode: string;
  setUpsertFaqKnowledgeBaseModalOpen: (dir: boolean) => void;
  open: boolean;
  initialValues: any;
  requireRoleToCreate: boolean;
  onSuccess: () => void;
}

const UpsertFaqKnowledgeBaseModal: React.FC<IUpsertFaqKnowledgeBaseModal> = (props) => {

  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch();
  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.entry) {
      errors.entry = 'Required';
    }
    if (!values.question) {
      errors.question = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleUpsertCall = () => {
    setIsSaving(true)
    if (props.mode === 'create') {
      dispatch(
        createKnowledgeBase(
          formik.values,
          () => {
            setIsSaving(false)
            if (props.onSuccess) {
                props.onSuccess();
            }
          },
          () => {
          }, 'FAQ created successfully', true

        ),
      );
    } else {
      dispatch(
        updateKnowledgeBase(
          { id: props.initialValues.id, ...formik.values },
          () => {
            setIsSaving(false)
            if (props.onSuccess) {
              props.onSuccess();
            }
          },
          () => {
          },  'FAQ updated successfully', true
        ),
      );
    }
  };

  return (
    <>
    <Loading loading={isSaving}/>
      <StandardModal
        title={props.mode === 'create' ? 'Add FAQ' : 'Edit FAQ'}
        open={props.open}
        onClose={() => props.setUpsertFaqKnowledgeBaseModalOpen(false)}
        actions={[
          {
            title: 'Cancel',
            callback: () => {
              props.setUpsertFaqKnowledgeBaseModalOpen(false)
            },
          },
          {
            title: props.mode === 'create' ? 'Create' : 'Save',
            callback: () => {
              formik.validateForm(formik.values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                  handleUpsertCall();
                }
              });
            },
          }

        ]}
      >
        <>
          <div style={{ display: 'block' }}>
            {Object.keys(formik.errors).length > 0 ? (
              <CustomAlert
                severity="error"
                title="Please verify fields below"
                message={
                'Marked input fields are required.'
                }
              />
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                      error={!!formik.errors.question}
                      label="Question"
                      fullWidth={true}
                      size="small"
                      name="question"
                      onChange={formik.handleChange}
                      style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                      value={formik.values.question}
                      variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!formik.errors.entry}
                    label="Answer"
                    fullWidth={true}
                    name="entry"
                    rows={5}
                    multiline
                    onChange={formik.handleChange}
                    // style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.entry}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      </StandardModal>
    </>
  );
};
export default UpsertFaqKnowledgeBaseModal;
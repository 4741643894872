import { FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import InputMask from 'react-input-mask';
import { enumToArrayOfStrings } from '../../shared/utils/gen-utils';
import { PRODUCT_CATEGORY, PRODUCT_UNIT } from '../../shared/constants/product.constants';
import { useDispatch } from 'react-redux';
import { createDrink, getDrinkCategories, updateDrink } from '../../store/actions/drinkActions';
import { useSelector } from '../../store';
import { IRootState } from '../../interfaces/store';

interface IUpsertDrinkModal {
    mode: string;
    setUpsertDrinkModalOpen: (dir: boolean) => void;
    open: boolean;
    initialValues: any;
    onSuccess: () => void;
}

const UpsertDrinkModal: React.FC<IUpsertDrinkModal> = (props) => {
    const theme = useSelector((state: any) => state.main.theme);
    const dispatch = useDispatch();
    const validate = (values: any) => {
        const errors = {} as any;

        const requiredFields = ['name', 'drink_category_id'];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Required';
            }
        })

        return errors;
    };

    const formik = useFormik({
        initialValues: props.initialValues,
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });
    useEffect(() => {
        dispatch(getDrinkCategories({}));
      }, []);

    const drink_categories = useSelector((state: IRootState) => state.main.drink_categories);
    const handleUpsertCall = () => {
        if (props.mode === 'create') {
            dispatch(
            createDrink(
                formik.values,
                () => {
                  if (props.onSuccess) {
                      props.onSuccess();
                  }
                },
                () => {
                }, 'Drink created successfully', true

              ),
            );
        } else {
            dispatch(
              updateDrink(
                { id: props.initialValues.id, ...formik.values },
                () => {
                  if (props.onSuccess) {
                    props.onSuccess();
                  }
                },
                () => {
                },  'Product updated successfully', true
              ),
            );
        }
    };

    return (


        <>
            <StandardModal
                maxWidth={'sm'}
                title={props.mode === 'create' ? 'Add Drink' : 'Edit Drink'}
                open={props.open}
                onClose={() => props.setUpsertDrinkModalOpen(false)}
                actions={[
                    {
                        title: props.mode === 'create' ? 'Create' : 'Save',
                        callback: () => {
                            formik.validateForm(formik.values).then((errors) => {
                                if (Object.keys(errors).length === 0) {
                                    handleUpsertCall();
                                }
                                else{
                                    console.log("ERRORS?", errors)
                                }
                            });
                        },
                    }
                ]}
            >
                <>
                    <div style={{ display: 'block' }}>
                        {Object.keys(formik.errors).length > 0 ? (
                            <CustomAlert
                                severity="error"
                                title="Please verify fields below"
                                message={
                                    'Marked input fields are required.'
                                }
                            />
                        ) : null}
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        error={!!formik.errors.name}
                                        label="Name"
                                        fullWidth={true}
                                        size="small"
                                        name="name"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <FormControlLabel control={<Switch
                                        sx={{'& .MuiSwitch-track': {
                                            backgroundColor: 'grey',
                                        },}}
                                        checked={formik?.values?.in_stock}
                                        onChange={(e) => formik.setFieldValue('in_stock', e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="In Stock" />
                                </Grid> */}
                                <Grid item xs={6} mt={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="drink_category_id">Select Drink Category</InputLabel>

                                        <Select
                                            id="drink_category_id"
                                            name="drink_category_id"
                                            label="Select Drink Category"
                                            labelId="drink_category_id"
                                            value={formik.values.drink_category_id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            size="small"
                                            >
                                            {Object.values(drink_categories)?.map((drinkCategory) => {
                                                return (
                                                    <MenuItem value={drinkCategory.id}>{drinkCategory.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={!!formik.errors.description}
                                        label="Description"
                                        fullWidth={true}
                                        size="small"
                                        name="description"
                                        multiline={true}
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.description}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </>
            </StandardModal>
        </>
    );
};
export default UpsertDrinkModal;
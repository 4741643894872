import { useNavigate } from "react-router-dom"
import { COLORS } from "../../shared/constants/layout-constants"
import { useState } from "react"
import { useSelector } from "../../store"
import { dateFormatted } from "../../shared/utils/date-utils"
import { TablePagination, Tooltip } from "@mui/material"
import { EditOutlined, Visibility } from "@mui/icons-material"
import Loading from "../general/Loading"
import MaterialTable from "@material-table/core"
import { tableIcons } from "../shared/MaterialTableShared"
import HttpClient, { objectToQuery } from "../../api/HttpClient"
import { usdFormatter } from "../../utils/general"

const client = new HttpClient();

interface IProductTable {
    title: string,
	fixed_query_params: Object
	onEditProduct: (user: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const ProductTable: React.FC<IProductTable> = (props) => {
	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [productTableKey, setProductTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Name', field: 'name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Brand', field: 'brand', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
        {
			title: 'Category', field: 'category', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Unit', field: 'unit', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
        {
			title: 'Description', field: 'description', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
        {
			title: 'Price', field: 'price', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300,
			},
			render: (row: any) => {
				return ((<span>{usdFormatter.format(row?.price!)}</span>))
			}
		},
		{
			title: 'Created', field: 'created_at', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return (
					<span>{dateFormatted(row.created_at, "mmddyyyy")}</span>
				)
			}
		},
		{
			title: "Actions",
			sorting: false,
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"View Product"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									navigate('/Products/' + row.id)
								}}
							/>
						</Tooltip>
						<Tooltip title={"Edit Product"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditProduct(row);
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<div key={productTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/product/query';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							...props.fixed_query_params
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
}

export default ProductTable
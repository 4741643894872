import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import Visibility from '@mui/icons-material/Visibility';
import { TablePagination, Tooltip } from '@mui/material';
import Loading from '../general/Loading';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { dateFormatted } from '../../shared/utils/date-utils';
import { COLORS } from '../../shared/constants/layout-constants';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useSelector } from '../../store';
import AvatarCircle from '../shared/avatar-circle';
import StandardModal from '../shared/StandardModal';
import { useDispatch } from 'react-redux';
import { deletePerson } from '../../store/actions/personActions';

const client = new HttpClient();
interface IPersonTable {
	title: string,
	fixed_query_params: Object
	onEditPerson: (person: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


const PersonTable: React.FC<IPersonTable> = (props) => {

	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [personTableKey, setPersonTableKey] = useState(0);
	const [deletePersonModalOpen, setDeletePersonModalOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>(null); // for delete
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;
	const dispatch = useDispatch();
	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: '', field: 'signed_avatar_url', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return <AvatarCircle signed_avatar_url={row.signed_avatar_url} size={35} />
			}
		},
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'First Name', field: 'first_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Last Name', field: 'last_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Created', field: 'created_at', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return (
					<span>{dateFormatted(row.created_at, "mmddyyyy")}</span>
				)
			}
		},
		{
			title: 'Phone', field: 'phone', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Email', field: 'email', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: "Actions",
			sorting: false,
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"View Person"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									navigate('/Customers/' + row.id)
								}}
							/>
						</Tooltip>
						<Tooltip title={"Edit Person"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditPerson(row);
								}}
							/>
						</Tooltip>
						<Tooltip title={"Delete Person"} placement="top">
							<DeleteOutline
								style={iconStyle}
								onClick={() => {
									setDeletePersonModalOpen(true)
									console.log("THE SELECTED ROW?", row)
									setSelectedRow(row)
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				title={`Delete ${selectedRow?.first_name} ${selectedRow?.last_name} Person`}
				open={deletePersonModalOpen}
				onClose={() => setDeletePersonModalOpen(false)}
				paragraphs={[`Are you sure you want to delete ${selectedRow?.first_name} ${selectedRow?.last_name}?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setDeletePersonModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						dispatch(deletePerson({
							id: selectedRow.id
						}, () => {
							setDeletePersonModalOpen(false)
						}, () => {}, "Person deleted successfully.", true))
					}
				}]}
				customActions={[]}
			/>
			<div key={personTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/person/query';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							...props.fixed_query_params
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
};

export default PersonTable;

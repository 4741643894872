import { FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Select, Switch, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import InputMask from 'react-input-mask';
import { enumToArrayOfStrings } from '../../shared/utils/gen-utils';
import { PRODUCT_CATEGORY, PRODUCT_UNIT } from '../../shared/constants/product.constants';
import { useDispatch } from 'react-redux';
import { createProduct, updateProduct } from '../../store/actions/productActions';
import { useSelector } from '../../store';

interface IUpsertProductModal {
    mode: string;
    setUpsertProductModalOpen: (dir: boolean) => void;
    open: boolean;
    initialValues: any;
    onSuccess: () => void;
}

const UpsertProductModal: React.FC<IUpsertProductModal> = (props) => {
    const theme = useSelector((state: any) => state.main.theme);
    const dispatch = useDispatch();
    const validate = (values: any) => {
        const errors = {} as any;

        const requiredFields = ['name', 'brand', 'category', 'unit', 'description', 'price'];

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Required';
            }
        })

        return errors;
    };

    const formik = useFormik({
        initialValues: props.initialValues,
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    const handleUpsertCall = () => {
        if (props.mode === 'create') {
            dispatch(
              createProduct(
                formik.values,
                () => {
                  if (props.onSuccess) {
                      props.onSuccess();
                  }
                },
                () => {
                }, 'Product created successfully', true

              ),
            );
        } else {
            dispatch(
              updateProduct(
                { id: props.initialValues.id, ...formik.values },
                () => {
                  if (props.onSuccess) {
                    props.onSuccess();
                  }
                },
                () => {
                },  'Product updated successfully', true
              ),
            );
        }
    };

    return (
        <>
            <StandardModal
                maxWidth={'lg'}
                title={props.mode === 'create' ? 'Add Product' : 'Edit Product'}
                open={props.open}
                onClose={() => props.setUpsertProductModalOpen(false)}
                actions={[
                    {
                        title: props.mode === 'create' ? 'Create' : 'Save',
                        callback: () => {
                            formik.validateForm(formik.values).then((errors) => {
                                if (Object.keys(errors).length === 0) {
                                    handleUpsertCall();
                                }
                                else{
                                    console.log("ERRORS?", errors)
                                }
                            });
                        },
                    }
                ]}
            >
                <>
                    <div style={{ display: 'block' }}>
                        {Object.keys(formik.errors).length > 0 ? (
                            <CustomAlert
                                severity="error"
                                title="Please verify fields below"
                                message={
                                    'Marked input fields are required.'
                                }
                            />
                        ) : null}
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        error={!!formik.errors.name}
                                        label="Name"
                                        fullWidth={true}
                                        size="small"
                                        name="name"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={formik?.values?.active}
					onChange={(e) => formik.setFieldValue('active', e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Active" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={!!formik.errors.description}
                                        label="Description"
                                        multiline
                                        fullWidth={true}
                                        size="small"
                                        name="description"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.description}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={!!formik.errors.price}
                                        label="Price"
                                        fullWidth={true}
                                        size="small"
                                        name="price"
                                        inputProps={
                                            {"type": "number",
                                            "step": "0.01"}
                                        }
                                        type = "number"
                                        onChange={(e) => {
                                            const regex = /^\d*(\.\d{0,2})?$/;  // Allow digits and up to two digits after the decimal point
                                            if (regex.test(e.target.value) || e.target.value === "") {
                                                formik.handleChange(e);
                                            }
                                        }}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.price}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={!!formik.errors.brand}
                                        label="Brand"
                                        fullWidth={true}
                                        size="small"
                                        name="brand"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.brand}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        id="category"
                                        name="category"
                                        label="Category"
                                        labelId="category"
                                        value={formik.values.category}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    >
                                        {enumToArrayOfStrings(PRODUCT_CATEGORY).map((pCategory: string) => {
                                            return (
                                                <MenuItem value={pCategory}>{pCategory}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        id="unit"
                                        name="unit"
                                        label="Unit"
                                        labelId="unit"
                                        value={formik.values.unit}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    >
                                        {enumToArrayOfStrings(PRODUCT_UNIT).map((pUnit: string) => {
                                            return (
                                                <MenuItem value={pUnit}>{pUnit}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>

                            </Grid>
                        </form>
                    </div>
                </>
            </StandardModal>
        </>
    );
};
export default UpsertProductModal;
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import { useSelector } from '../../store';
import { validateEmail } from '../../utils/validator';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import InputMask from 'react-input-mask';
import { updatePerson } from '../../store/actions/personActions';

interface IUpsertPersonModal {
  mode: string;
  setUpsertPersonModalOpen: (dir: boolean) => void;
  open: boolean;
  initialValues: any;
  requireRoleToCreate: boolean;
  onSuccess: () => void;
}

function validatePhoneNumber(input_str: any) {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(input_str);
}

const UpsertPersonModal: React.FC<IUpsertPersonModal> = (props) => {

  const dispatch = useDispatch();
  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.first_name) {
      errors.first_name = 'Required';
    }
    if (!values.last_name) {
      errors.last_name = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    if (values.email && !validateEmail(values.email)) {
      errors.email = 'Invalid Email';
    }
    if (!values.phone) {
      errors.phone = 'Required';
    }
    if (!validatePhoneNumber(values.phone)) {
      errors.phone = 'Invalid Phone Number';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleUpsertCall = () => {
    if (props.mode === 'create') {
      // dispatch(
      //   createPerson(
      //     formik.values,
      //     () => {
      //       closeModal();
      //       if (props.onSuccess) {
      //           props.onSuccess();
      //       }
      //     },
      //     () => {
      //     }, 'Person created successfully', true

      //   ),
      // );
    } else {
      dispatch(
        updatePerson(
          { id: props.initialValues.id, ...formik.values },
          () => {
            if (props.onSuccess) {
              props.onSuccess();
            }
          },
          () => {
          },  'Person updated successfully', true
        ),
      );
    }
  };

  return (
    <>
      <StandardModal
        title={props.mode === 'create' ? 'Add Person' : 'Edit Person'}
        open={props.open}
        onClose={() => props.setUpsertPersonModalOpen(false)}
        actions={[
          {
            title: props.mode === 'create' ? 'Create' : 'Save',
            callback: () => {
              formik.validateForm(formik.values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                  handleUpsertCall();
                }
              });
            },
          }
        ]}
      >
        <>
          <div style={{ display: 'block' }}>
            {Object.keys(formik.errors).length > 0 ? (
              <CustomAlert
                severity="error"
                title="Please verify fields below"
                message={
                'Marked input fields are required.'
                }
              />
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.first_name}
                    label="First Name"
                    fullWidth={true}
                    size="small"
                    name="first_name"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.last_name}
                    label="Last Name"
                    fullWidth={true}
                    size="small"
                    name="last_name"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formik.values.phone}
                    disabled={false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                  <TextField
                    error={!!formik.errors.phone}
                    label="Phone"
                    fullWidth={true}
                    size="small"
                    name="phone"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.phone}
                    variant="outlined"
                  />
                  </InputMask>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.email}
                    label="Email"
                    fullWidth={true}
                    size="small"
                    name="email"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      </StandardModal>
    </>
  );
};
export default UpsertPersonModal;
import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import Visibility from '@mui/icons-material/Visibility';
import { TablePagination, Tooltip } from '@mui/material';
import Loading from '../general/Loading';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { dateFormatted } from '../../shared/utils/date-utils';
import { COLORS } from '../../shared/constants/layout-constants';
import { Delete, EditOutlined } from '@mui/icons-material';
import { useSelector } from '../../store';
import StandardModal from '../shared/StandardModal';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { deleteKnowledgeBase } from '../../store/actions/knowledgebaseActions';

const client = new HttpClient();
interface IFaqKnowledgeBaseTable {
	title: string,
	onEditFaqKnowledgeBase: (faqknowledgebase: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


const FaqKnowledgeBaseTable: React.FC<IFaqKnowledgeBaseTable> = (props) => {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [faqknowledgebaseTableKey, setFaqKnowledgeBaseTableKey] = useState(0);
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [selectedFaqData, setSelectedFaqData] = useState({} as any)
	const [faqTableKey, setFaqTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, width:"10%"
		},
		{
			title: 'Question', field: 'question', width:"35%",
			render: (row: any) => {
				return (
				<div style={{ maxHeight:'175px', overflowX:'auto', overflowY:'auto', lineBreak:"anywhere" }}>
					{row?.question}
				</div>
				)
			}
		},
		{
			title: 'Answer', field: 'entry', width:"35%",
			render: (row: any) => {
				return (
				<div style={{ maxHeight:'175px', overflowX:'auto', overflowY:'auto', lineBreak:"anywhere" }}>
					{row?.entry}
				</div>
				)
			}
		},
		{
			title: "Actions",
			sorting: false,
			width:"20%",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"Edit FAQ"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditFaqKnowledgeBase(row);
								}}
							/>
						</Tooltip>
						<Tooltip title={"Delete FAQ"} placement="top">
							<Delete
								style={iconStyle}
								onClick={() => {
									setSelectedFaqData({
										...row
									})
									setActivationModalOpen(true);
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				title={'Delete FAQ'}
				open={activationModalOpen}
				onClose={() => setActivationModalOpen(false)}
				paragraphs={[`Are you sure you want to delete this FAQ?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setActivationModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true)
						dispatch(deleteKnowledgeBase({ id: selectedFaqData.id }
							, () => {
								enqueueSnackbar(
									`FAQ deleted Successfully.`,
									{ variant: "success" }
								)
								setActivationModalOpen(false)
									;
								setFaqTableKey(faqTableKey + 1);
								setProcessing(false)
							}, () => {
								enqueueSnackbar(
									`Error while deleting FAQ.`,
									{ variant: "error" }
								);
								setProcessing(false)
								setActivationModalOpen(false)
							}))
					}
				}]}
				customActions={[]}
			/>
			<div key={faqknowledgebaseTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/knowledge-base/search';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							isFaq:true
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
};

export default FaqKnowledgeBaseTable;

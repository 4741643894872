export enum LOG_LEVEL {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  FATAL = 'FATAL'
}

export enum APP_SIDE {
  FRONT_SIDE = 'FRONT_SIDE',
  SERVER_SIDE = 'SERVER_SIDE'
}

export enum CUSTOM_LOG_FILE_NAME {
  QA = 'QA',
}

export enum LOG_TYPE {
  DEBUG_CHAT_STREAM = 'DEBUG_CHAT_STREAM',
  NON_GOAL_QUESTION_ANSWERED = 'NON_GOAL_QUESTION_ANSWERED',
  NON_GOAL_QUESTION_UNANSWERED = 'NON_GOAL_QUESTION_UNANSWERED',
  NON_GOAL_QUESTION_NO_VECTOR = 'NON_GOAL_QUESTION_NO_VECTOR',
}
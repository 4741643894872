
import * as React from 'react';
import { Button, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ProductTable from '../../../components/product/ProductTable';
import Switch from '@mui/material/Switch';
import { AddCircleOutline } from '@mui/icons-material';
import UpsertProductModal from '../../../components/product/UpsertProductModal';
import { useSelector } from '../../../store';
import { PRODUCT_CATEGORY, PRODUCT_UNIT } from '../../../shared/constants/product.constants';
import { syncMeevoServices } from '../../../store/actions/productActions';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../../components/general/Loading';

const upsertProductDefaultValues = {
	name: '',
	active: true,
	brand: '',
	category: PRODUCT_CATEGORY.PHYSICAL_PRODUCT,
	unit: PRODUCT_UNIT.PIECE,
	description: '',
	price: null
}

function ProductsPage(props: any) {

	const [showInactive, setShowInactive] = useState(false);
	const [upsertProductModalOpen, setUpsertProductModalOpen] = useState(false);
	const [upsertProductModalMode, setUpsertProductModalMode] = useState<'create' | 'edit'>('create');
	const [upsertProductModalInitialValues, setUpsertProductModalInitialValues] = useState({...upsertProductDefaultValues} as any);
	const [upsertProductModalKey, setUpsertProductModalKey] = useState(0);
	const products_updated = useSelector((state) => state.main.meta?.products?.updated_at || 0);
	const theme = useSelector((state) => state.main.theme);
	const [processing, setProcessing] = useState(false);


	const dispatch = useDispatch();

	const queryParams = {} as any

	if (!showInactive){
		queryParams.active = true
	}

	return <>
		<Loading loading={processing} />
		<UpsertProductModal 
			key={upsertProductModalKey}
			mode={upsertProductModalMode} 
			setUpsertProductModalOpen={setUpsertProductModalOpen} 
			open={upsertProductModalOpen} 
			initialValues={upsertProductModalInitialValues} 
			onSuccess={() => {
				setUpsertProductModalOpen(false);
			}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertProductModalKey(upsertProductModalKey + 1);
					setUpsertProductModalInitialValues({...upsertProductDefaultValues})
					setUpsertProductModalMode('create');
					setUpsertProductModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add Product
				</Button>
			</div>

			<div style={{ flex: 1, textAlign: 'left' }}>
				<Button onClick={() => {
					setProcessing(true)
					dispatch(syncMeevoServices({}
						, () => {
							enqueueSnackbar(
								`Meevo Services have been synced successfully`,
								{ variant: "success" }
							)
							setProcessing(false)

						}, () => {
							enqueueSnackbar(
								`Something went wrong while syncing Meevo Services`,
								{ variant: "error" }
							);
							setProcessing(false)

						}))
				}} variant='outlined'>
					Sync Meevo Services
				</Button>
			</div>

			<div style={{ flex: 1, textAlign: 'right' }}>
				<FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={showInactive}
					onChange={(e) => setShowInactive(e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Show Inactive" />
			</div>

		</div>
		<ProductTable 
		key={showInactive.toString() + products_updated.toString()} 
		title={'Products'} 
		fixed_query_params={queryParams}
		onEditProduct={(product: any) => {
			console.log(product)
			setUpsertProductModalMode('edit');
			setUpsertProductModalOpen(true);
			setUpsertProductModalKey(upsertProductModalKey + 1);
			setUpsertProductModalInitialValues({
				id: product.id,
				name: product.name,
				active: product.active,
				brand: product.brand,
				category: product.category,
				unit: product.unit,
				description: product.description,
				price: product.price
			})
		}} 
		/>
	</>
}

export default ProductsPage
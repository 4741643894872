import { useNavigate } from "react-router-dom"
import { COLORS } from "../../shared/constants/layout-constants"
import { useState } from "react"
import { useSelector } from "../../store"
import { dateFormatted } from "../../shared/utils/date-utils"
import { TablePagination, Tooltip } from "@mui/material"
import { EditOutlined, ToggleOff, ToggleOn, Visibility, Delete } from "@mui/icons-material"
import Loading from "../general/Loading"
import MaterialTable from "@material-table/core"
import { tableIcons } from "../shared/MaterialTableShared"
import HttpClient, { objectToQuery } from "../../api/HttpClient"
import { usdFormatter } from "../../utils/general"
import { useDispatch } from "react-redux"
import { updateDrink } from '../../store/actions/drinkActions';
import { enqueueSnackbar } from "notistack"
import StandardModal from "../shared/StandardModal"
import { deleteDrink } from '../../store/actions/drinkActions';


const client = new HttpClient();

interface IDrinksTable {
    title: string,
	fixed_query_params: Object
	onEditDrink: (user: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const DrinkTable: React.FC<IDrinksTable> = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [processing, setProcessing] = useState(false);
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [selectedDrinkData, setSelectedDrinkData] = useState({} as any)

	const [drinkTableKey, setDrinkTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: 'Id', field: 'r.id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (rowData:any) => {
				return (
				  <div>
					{rowData?.id}
				  </div>
				)
			}
		},
		{
			title: 'Name', field: 'r.name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (rowData:any) => {
				return (
				  <div>
					{rowData?.name}
				  </div>
				)
			}
		},
		{
			title: 'Drink Category', field: 'drinkCategory.name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (rowData:any) => {
				return (
				  <div>
					{rowData?.drinkCategory?.name}
				  </div>
				)
			}
		},
		{
			title: 'Description', field: 'r.description', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (rowData:any) => {
				return (
				  <div>
					{rowData?.description}
				  </div>
				)
			}
		},
		{
			title: "Actions",
			sorting: false,
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>

						<Tooltip title={"Edit Drink"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditDrink(row);
								}}
							/>
						</Tooltip>
							{row.in_stock ? <Tooltip title={"In Stock"} placement="top">
							<ToggleOn
								style={iconStyle}
								onClick={() => {
									setProcessing(true)
									dispatch(updateDrink({ id: row.id, in_stock: false }
										, () => {
											enqueueSnackbar(
												`Drink Updated Successfully`,
												{ variant: "success" }
											)
											setProcessing(false)
										}, () => {
											enqueueSnackbar(
												`Error updating the key`,
												{ variant: "error" }
											);
											setProcessing(false)
										}))
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Out of Stock"} placement="top">
								<ToggleOff
									style={{...iconStyle, color: COLORS.GREY_DARK}}
									onClick={() => {
										setProcessing(true)
										dispatch(updateDrink({ id: row.id, in_stock: true }
											, () => {
												enqueueSnackbar(
													`Drink Updated Successfully`,
													{ variant: "success" }
												)
												setProcessing(false)
											}, () => {
												enqueueSnackbar(
													`Error updating the key`,
													{ variant: "error" }
												);
												setProcessing(false)
											}))									
										}}
								/>
							</Tooltip>
						}
						<Tooltip title={"Delete Drink"} placement="top">
							<Delete
								style={iconStyle}
								onClick={() => {
									setSelectedDrinkData({
										...row
									})
									setActivationModalOpen(true);
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				title={'Delete Drink'}
				open={activationModalOpen}
				onClose={() => setActivationModalOpen(false)}
				paragraphs={[`Are you sure you want to delete this drink?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setActivationModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true)
						dispatch(deleteDrink({ id: selectedDrinkData.id }
							, () => {
								enqueueSnackbar(
									`Drink deleted Successfully.`,
									{ variant: "success" }
								)
								setActivationModalOpen(false)
									;
								setDrinkTableKey(drinkTableKey + 1);
								setProcessing(false)
							}, () => {
								enqueueSnackbar(
									`Error while deleting drink.`,
									{ variant: "error" }
								);
								setProcessing(false)
								setActivationModalOpen(false)
							}))
					}
				}]}
				customActions={[]}
			/>
			<div key={drinkTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/drinks/search';
						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							...props.fixed_query_params
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
}

export default DrinkTable
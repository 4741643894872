import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import { TablePagination, Tooltip } from '@mui/material';
import Loading from '../general/Loading';
import { useSelector } from '../../store';
import { IConversation } from '../../shared/interfaces/model/conversation.interface';
import { IGoalResponse } from '../../shared/interfaces/model/goal-response.interface';
import { GOAL_RESPONSE_TYPE } from '../../shared/constants/conversation.constants';

interface IConversationTable {
	conversations: IConversation[]
}

const ConversationTable: React.FC<IConversationTable> = (props) => {

	const [processing, setProcessing] = useState(false);
	const [conversationTableKey, setConversationTableKey] = useState(0);

	const goalResponses = useSelector((state) => Object.values(state.main.goal_responses));
	const drinks = useSelector((state) => Object.values(state.main.drinks));
	const products = useSelector((state) => Object.values(state.main.products));

	const theme = useSelector((state) => state.main.theme);
	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}
    
	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Status', field: 'status', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Drink', field: 'response_drink', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				
				let itemName = '';
				const matchedResponse = goalResponses.find((x: IGoalResponse) => x.conversation_id === row.id && x.goal_response_type === GOAL_RESPONSE_TYPE.DRINK);
				if (matchedResponse){
					const matchedItem = drinks.find((x: any) => x.id === Number(matchedResponse.integer_value));
					if (matchedItem){
						itemName = matchedItem.name;
					}
				}

				return (
					<span>{itemName}</span>
				)
			}
		},
		{
			title: 'Shoe Shine', field: 'response_shoe_shine', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				
				let itemName = '';
				const matchedResponse = goalResponses.find((x: IGoalResponse) => x.conversation_id === row.id && x.goal_response_type === GOAL_RESPONSE_TYPE.SHOE_SHINE);
				if (matchedResponse){
					itemName = (matchedResponse.boolean_value || "false").toString()
				}

				return (
					<span>{itemName}</span>
				)
			}
		},
		{
			title: 'Shirt Loan', field: 'response_shirt_loan', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				
				let itemName = '';
				const matchedResponse = goalResponses.find((x: IGoalResponse) => x.conversation_id === row.id && x.goal_response_type === GOAL_RESPONSE_TYPE.SHIRT_LOAN);
				if (matchedResponse){
					itemName = (matchedResponse.boolean_value || "false").toString()
				}

				return (
					<span>{itemName}</span>
				)
			}
		},
        {
			title: 'Products', field: 'response_products', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				
				let itemNames = [];
				const matchedResponse = goalResponses.filter((x: IGoalResponse) => x.conversation_id === row.id && x.goal_response_type === GOAL_RESPONSE_TYPE.PRODUCT);
				
				for (let i=0; i<matchedResponse.length; i++){
					const matchedItem = products.find((x: any) => x.id === Number(matchedResponse[i].integer_value));
					if (matchedItem){
						itemNames.push(matchedItem.name);
					}
				}

				return (
					<span>{itemNames.join(',')}</span>
				)
			}
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<div key={conversationTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={"Conversations"}
					columns={columns}
					style={mainStyle}
					data={props.conversations}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
};

export default ConversationTable;

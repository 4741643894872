import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import Visibility from '@mui/icons-material/Visibility';
import { TablePagination, Tooltip } from '@mui/material';
import Loading from '../general/Loading';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { dateFormatted } from '../../shared/utils/date-utils';
import { COLORS } from '../../shared/constants/layout-constants';
import { EditOutlined } from '@mui/icons-material';
import { useSelector } from '../../store';

const client = new HttpClient();
interface IKnowledgeBaseTable {
	title: string,
	onEditKnowledgeBase: (knowledgebase: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


const KnowledgeBaseTable: React.FC<IKnowledgeBaseTable> = (props) => {

	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [knowledgebaseTableKey, setKnowledgeBaseTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, width:"10%"
		},
		{
			title: 'Entry', field: 'entry', width:"50%",
			render: (row: any) => {
				return (
				<div style={{ maxHeight:'175px', overflowX:'auto', overflowY:'auto', lineBreak:"anywhere" }}>
					{row?.entry}
				</div>
				)
			}
		},
		{
			title: "Actions",
			sorting: false,
			width:"20%",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						{/* <Tooltip title={"View KnowledgeBase"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									navigate('/KnowledgeBases/' + row.id)
								}}
							/>
						</Tooltip> */}
						<Tooltip title={"Edit KnowledgeBase"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditKnowledgeBase(row);
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<div key={knowledgebaseTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/knowledge-base/search';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							isFaq:false
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
};

export default KnowledgeBaseTable;

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { getPersonPageData } from '../../../store/actions/personActions';
import Loading from '../../../components/general/Loading';
import { COLORS, MARGIN_MEDIUM } from '../../../shared/constants/layout-constants';
import AvatarCircle from '../../../components/shared/avatar-circle';
import MincyNotesTable from '../../../components/mincy-notes/MincyNotesTable';
import { MINCY_NOTES_TYPE } from '../../../shared/constants/mincy-notes-type.constanats';
import { IConversation } from '../../../shared/interfaces/model/conversation.interface';
import { useSelector } from '../../../store';
import ConversationTable from '../../../components/conversation/ConversationTable';

const authContStyle = {
  marginTop: 16,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const iconStyle = {
  color: COLORS.BRAND_PRIMARY,
  fontSize: 24,
  cursor: 'pointer',
};

function PersonViewPage(props: any) {
  const params = useParams<{ personId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [person, setPerson] = useState({} as any);
  const [personFetched, setPersonFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const mincynotess_updated = useSelector((state) => state.main.meta?.mincy_notes?.updated_at || 0);
  const conversations = useSelector((state) => Object.values(state.main.conversations));
  const personConversations = conversations.filter((x: IConversation) => x.person_id === Number(params.personId));

  useEffect(() => {
    if (!isNaN(Number(params.personId))) {
      dispatch(getPersonPageData({ person_id: params.personId }, () => setPersonFetched(true)));
    }
  }, []);

  const persons = useSelector((state: IRootState) => state.main.persons);

  useEffect(() => {
    if (personFetched) {
      const matchedPerson = Object.values(persons).find((x) => x.id === Number(params.personId)) as any;

      if (!matchedPerson) {
        navigate('/Customers');
      } else {
        setPerson(matchedPerson);
        setLoading(false);
      }
    }
  }, [persons, personFetched]);

  <Loading loading={loading} />;

  if (loading) {
    return <div></div>;
  }


  return (
    <>
      {person && <Box

      >

        <h3>{person?.first_name} {person?.last_name}</h3>
        <div style={{marginTop: MARGIN_MEDIUM, marginBottom: MARGIN_MEDIUM}}>
          <AvatarCircle signed_avatar_url={person?.signed_avatar_url} size={100} />
        </div>
        <div>
          <b>Id: {person?.id}</b>
        </div>
        <div>
          <b>Email: {person?.email}</b>
        </div>
        <div>
          <b>Phone: {person?.phone}</b>
        </div>
        <div>
          <b>Meevo ID:</b>
        </div>
        <div>
          <b>Gender: {person?.gender}</b>
        </div>
        <div>
          <b>Created: {person?.created_at}</b>
        </div>
        <Box sx={{ marginTop: '50px'}}>
        <ConversationTable 
			conversations={personConversations}
		/>
          <MincyNotesTable
            key={mincynotess_updated.toString()}
            type={MINCY_NOTES_TYPE.CUSTOMER} 
            userId={params.personId}
          />
      </Box>
      </Box>
      
      }
    </>
  );
}

export default PersonViewPage;

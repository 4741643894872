import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./genericAction";

export const createProduct = genericAction(ACTIONS.CREATE_PRODUCT);
export const updateProduct = genericAction(ACTIONS.UPDATE_PRODUCT);
export const getProduct = genericAction(ACTIONS.GET_PRODUCT_WITH_ASSOCIATED)
export const getProductSelect = genericAction(ACTIONS.GET_PRODUCT_SELECT)
export const createProductProduct = genericAction(ACTIONS.CREATE_PRODUCT_PRODUCT)
export const deleteProductProduct = genericAction(ACTIONS.DELETE_PRODUCT_PRODUCT)
export const syncMeevoServices = genericAction(ACTIONS.SYNC_MEEVO_SERVICES)

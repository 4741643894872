
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Login from './pages/public/auth/LoginPage';
import PhoneLogin from './pages/public/auth/PhoneLoginPage';
import ForgotPassword from './pages/public/auth/ForgotPasswordPage';
import ResetPassword from './pages/public/auth/ResetPasswordPage';
import ResetPasswordNextStep from './pages/public/auth/ResetPasswordNextStepPage';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Privacy from './pages/public/privacy/PrivacyPage';
import Terms from './pages/public/terms/Terms';
import VerifyEmail from './pages/private/site/VerifyEmail';
import { COLORS } from './shared/constants/layout-constants';
import { useSelector } from './store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGlobalSettings } from './store/actions/globalSettingsActions';
import { IRootState } from './interfaces/store';

const authContStyle = {
	marginTop: 16, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
}

function AppPublic(props: any) {

	const dispatch = useDispatch();
	const theme = useSelector((state: IRootState) => state.main.theme);
	
	useEffect(() => {
			dispatch(getGlobalSettings({}, () => {}))
	}, [])

	const navigate = useNavigate();

	return (
		<div style={{width: '100vw', height: '100vh', backgroundColor: theme.colors.background, color: theme.colors.font}}>
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar style={{ backgroundColor: COLORS.BRAND_PRIMARY_DARK }}>
				<Toolbar>
					<img onClick={() => {
						navigate('/')
					}} alt={'logo'} width={115} style={{ cursor: 'pointer', float: 'left' }} src={process.env.PUBLIC_URL + '/logo_white.png'}></img>
					{/* <Typography style={{ cursor: 'pointer' }} onClick={() => {navigate('/')}} variant="h6" noWrap component="div">
            mincy
          </Typography> */}
				</Toolbar>
			</AppBar>




			<Box component="main" sx={{ width: '100%', marginTop: 8 }}>

				<Routes>
					<Route path="/" element={<div style={authContStyle}></div>} />
					<Route path="ResetPasswordNextStep" element={<div style={authContStyle}>
						<ResetPasswordNextStep />
					</div>} />
					<Route path="PhoneLogin" element={<div style={authContStyle}>
						<PhoneLogin />
					</div>} />
					<Route path="Admin" element={<div style={authContStyle}>
						<Login />
					</div>} />
					<Route path="ForgotPassword" element={<div style={authContStyle}>
						<ForgotPassword />
					</div>} />
					<Route path="ResetPassword" element={<div style={authContStyle}>
						<ResetPassword />
					</div>} />
					<Route path="VerifyEmail" element={<div style={authContStyle}>
						<VerifyEmail />
					</div>} />
					<Route path="TestPhone" element={
						<div>
							<div onClick={() => window.open("mincy://open", "_blank")} style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}>
								LAUNCH APP 1
							</div>
							<br /><br />
							<a href="mincy://open">LAUNCH APP 2</a>
						</div>} />
					<Route path="Privacy" element={<Privacy />} />
					<Route path="Terms" element={<Terms />} />
				</Routes>
			</Box>
		</Box>
		</div>
		
	)

}

export default AppPublic

import * as React from 'react';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddCircleOutline } from '@mui/icons-material';
import { useSelector } from '../../../store';
import UpsertDrinkModal from '../../../components/drinks/UpsertDrinkModal';
import DrinkTable from '../../../components/drinks/DrinksTable';

const upsertDrinkDefaultValues = {
	name: '',
	in_stock: true,
	drink_category_id: 1
}

function ProductsPage(props: any) {

	const [showInStock, setShowInStock] = useState(false);
	const [upsertDrinkModalOpen, setUpsertDrinkModalOpen] = useState(false);
	const [upsertDrinkModalMode, setUpsertDrinkModalMode] = useState<'create' | 'edit'>('create');
	const [upsertDrinkModalInitialValues, setUpsertDrinkModalInitialValues] = useState({...upsertDrinkDefaultValues} as any);
	const [upsertDrinkModalKey, setUpsertDrinkModalKey] = useState(0);
	const drinks_updated = useSelector((state) => state.main.meta?.drinks?.updated_at || 0);
	const theme = useSelector((state) => state.main.theme);

	const dispatch = useDispatch();

	const queryParams = {} as any

	if (!showInStock){
		queryParams.in_stock = true
	}

	return <>
		<UpsertDrinkModal 
			key={upsertDrinkModalKey}
			mode={upsertDrinkModalMode} 
			setUpsertDrinkModalOpen={setUpsertDrinkModalOpen} 
			open={upsertDrinkModalOpen} 
			initialValues={upsertDrinkModalInitialValues} 
			onSuccess={() => {
				setUpsertDrinkModalOpen(false);
			}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertDrinkModalKey(upsertDrinkModalKey + 1);
					setUpsertDrinkModalInitialValues({...upsertDrinkDefaultValues})
					setUpsertDrinkModalMode('create');
					setUpsertDrinkModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add Drink
				</Button>
			</div>
			{/* <div style={{ flex: 1, textAlign: 'right' }}>
				<FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={showInStock}
					onChange={(e) => setShowInStock(e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Show Inactive" />
			</div> */}

		</div>
		<DrinkTable 
		key={showInStock.toString() + drinks_updated.toString()} 
		title={'Drinks'} 
		fixed_query_params={queryParams}
		onEditDrink={(drink: any) => {
			console.log("drink", drink);
			setUpsertDrinkModalMode('edit');
			setUpsertDrinkModalOpen(true);
			setUpsertDrinkModalKey(upsertDrinkModalKey + 1);
			setUpsertDrinkModalInitialValues({
				id: drink.id,
				name: drink.name,
				in_stock: drink.in_stock,
				drink_category_id: drink.drink_category_id,
				description: drink.description
			})
		}} 
		/>
	</>
}

export default ProductsPage
import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import { Button, TablePagination, Tooltip } from '@mui/material';
import Loading from '../general/Loading';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { COLORS } from '../../shared/constants/layout-constants';
import { AddCircleOutline, EditOutlined } from '@mui/icons-material';
import { useSelector } from '../../store';
import { useDispatch } from 'react-redux';
import UpsertMincyNotesModal from './UpsertMincyNotesModal';
import { dateFormatted } from '../../shared/utils/date-utils';
const moment = require('moment-timezone');


const client = new HttpClient();
interface IMincyNotesTable {
	title: string,
	onEditMincyNotes: (mincynotes: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const upsertMincyNotesDefaultValues = {
	note: ''
}

const MincyNotesTable: React.FC<any> = (props) => {

	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [mincynotesTableKey, setMincyNotesTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { type, userId } = props;

	const [upsertMincyNotesModalOpen, setUpsertMincyNotesModalOpen] = useState(false);
	const [upsertMincyNotesModalMode, setUpsertMincyNotesModalMode] = useState<'create' | 'edit'>('create');
	const [upsertMincyNotesModalInitialValues, setUpsertMincyNotesModalInitialValues] = useState({...upsertMincyNotesDefaultValues} as any);
	const [upsertMincyNotesModalKey, setUpsertMincyNotesModalKey] = useState(0);
	const dispatch = useDispatch();


	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, width:"10%"
		},

		
		{
			title: 'Note', field: 'note', width:"50%",
			render: (row: any) => {
				return (
				<div style={{ maxHeight:'175px', overflowX:'auto', overflowY:'auto', lineBreak:"anywhere" }}>
					{row?.note}
				</div>
				)
			}
		},
		{
			title: 'Created At', field: 'created_at', width:"15%",
			render: (row: any) => {
				return (
					<span>{moment(row?.created_at).format('MM/DD/YYYY hh:mm')}</span>
				)
			}
		},
		{
			title: 'Updated At', field: 'updated_at', width:"15%",
			render: (row: any) => {
				return (
				<span>{moment(row?.updated_at).format('MM/DD/YYYY hh:mm')}</span>
				)
			}
		},
		{
			title: "Actions",
			sorting: false,
			width:"10%",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"Edit Mincy Notes"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									console.log(row)
									setUpsertMincyNotesModalMode('edit');
									setUpsertMincyNotesModalOpen(true);
									setUpsertMincyNotesModalKey(upsertMincyNotesModalKey + 1);
									setUpsertMincyNotesModalInitialValues({
										id: row.id,
										note: row.note
									})
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		}
	];

	return (
		<div >
			<UpsertMincyNotesModal 
				key={upsertMincyNotesModalKey}
				mode={upsertMincyNotesModalMode}
				type= {type}
				userId = {userId}
				setUpsertMincyNotesModalOpen={setUpsertMincyNotesModalOpen} 
				open={upsertMincyNotesModalOpen} 
				initialValues={upsertMincyNotesModalInitialValues} 
				requireRoleToCreate={false} 
				onSuccess={() => {
					setUpsertMincyNotesModalOpen(false);
				}} 
			/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertMincyNotesModalKey(upsertMincyNotesModalKey + 1);
					setUpsertMincyNotesModalInitialValues({...upsertMincyNotesDefaultValues})
					setUpsertMincyNotesModalMode('create');
					setUpsertMincyNotesModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add Mincy Notes
				</Button>
			</div>
		</div>
		
			<Loading loading={processing} />
			<div key={mincynotesTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={'Notes'}
					columns={columns}
					// style={mainStyle}
					data={(query) => new Promise((resolve, reject) => {
						let url = 'api/mincy-notes/search';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							userId: userId,
							type: type
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						url += objectToQuery(params);

						(async () => {
							try {
								const result = await client.get(url);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					})}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>
		</div>

	);
};

export default MincyNotesTable;
